import React from 'react';
import GlobalStyle from './globalStyles';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';

// Pages
import Home from './pages/Home';
import Pricing from './pages/PricingPage';
import AboutUs from './pages/AboutUsPage';
import Credits from './pages/CreditsPage';
import PrivacyPolicies from './pages/PrivacyPoliciesPage';
import Disclaimer from './pages/DisclaimerPage'
import Construction from './pages/ConstructionPage';
import Login from './pages/LoginPage';
import Productores from './pages/ProductoresPage';
import Siniestros from './pages/SiniestrosPage';
import SiniestrosForm from './pages/SiniestrosFormPage'


// Components
import Footer from './components/Footer/Footer';

function App() {
  return(
    <Router>
      <GlobalStyle/>
      <Navbar/>
      <Routes>
        <Route exact path="/" element={<Home/>} />
        <Route path="/productos" element={<Pricing/>} />
        <Route path="/quienes-somos" element={<AboutUs/>} />
        <Route path="/creditos" element={<Credits/>} />
        <Route path="/politicas-de-privacidad" element={<PrivacyPolicies/>} />
        <Route path="/descargo-de-responsabilidad" element={<Disclaimer/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/asistencia-y-siniestros" element={<Siniestros/>} />
        
        <Route path="/reportar-siniestro" element={<SiniestrosForm/>} />
        
        <Route path="/productores" element={<Productores/>} />



        <Route path="/pagina-en-desarrollo" element={<Construction/>} />

      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
