

export const creditsOne = {
	reverse: false,
	inverse: false,
	topLine: {
		text: '',
	},
	headline: 'Gracias!',
	subtitle: 'Agradecemos la colaboración de: ',
	description: ['Octavio Garcia - Diseñador y programador','Benjamin Angelone - Diseñador y programador', 'Agustin Barrigo - Video página principal'],
	buttonLabel: 'Volver',

	linkTo: '/',
	redir: null,
	imgStart: 'start',
	img: './assets/svg/Work.svg',
	start: 'true',
};

