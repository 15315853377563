import styled from 'styled-components';
import { motion } from 'framer-motion';


export const SearchBarContainer = styled.div`
  padding: 1rem;
  text-align: center;
  margin: 1em 0;
  align-items: center;
  display: block;
  width: 100%;

`;

export const SearchBarSelect = styled.select`
  width: 160px;
  height: 3rem;
	padding: 6px 10px;
	font-weight: 700;
	font-size: 0.8rem;
	line-height: 18px;
	letter-spacing: 1.54px;
	text-transform: uppercase;
	cursor: pointer;
	background: none;
	border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
	white-space: nowrap;
	padding: '10px 20px';
	font-size: '16px';
	outline: none;
	border: 2px solid #0c4577;
	position: relative;
	overflow: hidden;
`;

export const SearchBarInput = styled.input`
  height: 3rem;
  width: 300px;
	padding: 6px 10px;
	font-weight: 700;
	font-size: 0.8rem;
	line-height: 18px;
	letter-spacing: 1.54px;
	text-transform: uppercase;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
	cursor: text;
	background: none;
  color: '#0c4577';
	white-space: nowrap;
	padding: '10px 20px';
	font-size: '16px';
	outline: none;
	border: 2px solid #0c4577;
	position: relative;
	overflow: hidden;
`;


export const SearchBarOption = styled.option`
	font-weight: 700;
	font-size: 0.8rem;
	text-transform: uppercase;
  color: '#0c4577';
	border-radius: 4px, 0px, 0px, 4px;
	white-space: nowrap;
	font-size: '16px';
	outline: none;
	border: 2px solid #0c4577;
	cursor: pointer;
	position: relative;
	overflow: hidden;
`;


export const SearchBarButton = styled(motion.button)`
	height: 3rem;
  width: 100%;
  max-width: 460px;
	padding: 6px 10px;
	font-weight: 700;
	font-size: 0.8rem;
	line-height: 18px;
	letter-spacing: 1.54px;
	text-transform: uppercase;
	cursor: pointer;
	background: none;
	color: ${({ inverse }) => (inverse ? '#0c4577' : 'white')};
  border-radius: 4px;
	white-space: nowrap;
	font-size: '16px';
	outline: none;
	border: 2px solid ${({ inverse }) => (inverse ? '#0c4577' : 'white')};
	cursor: pointer;
	position: sticky;
	overflow: hidden;
	
	.active{
		color: ${({ inverse }) => (inverse ? 'white' : 'black')};
	}

	&:before {
		background: ${({ inverse }) => (inverse ? '#0c4577' : 'white')};
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: -1;
		transition: all 0.6s ease;
		width: 100%;
		height: 0%;
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:hover:before {
		height: 500%;
	}

	&:hover {
		color: ${({ inverse }) => (inverse ? 'white' : 'black')};
	}
`;
