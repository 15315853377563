import React from 'react';

import { BsShop, BsBriefcase } from 'react-icons/bs';
import { AiOutlineHome, AiOutlineCar, AiOutlineUsergroupAdd } from 'react-icons/ai';
import { BiSupport, BiDollar } from 'react-icons/bi';
import { SlBadge, SlOptions } from 'react-icons/sl'

import { 
	automotor_wapp,
	hogar_wapp,
	personas_wapp,
	empresas_wapp,
	art_wapp,
	consultar_wapp
} from '../ReactGlobalData';
const iconStyle = (Icon) => <Icon size="3rem" color="#0f0f0f" />;

export const servicesData = [
	{
		name: 'Seguros de Automotores',
		description: 'Autos, motos, barcos, ...',
		icon: iconStyle(AiOutlineCar),
		redir: automotor_wapp,
		link: null,
		imgClass: 'one',
		url: './assets/services/template.png',
	},
	{
		name: 'Seguros del Hogar',
		description: 'Asegura tu casa, tus equipos electrónicos, ...',
		icon: iconStyle(AiOutlineHome),
		redir: hogar_wapp,
		link: null,
		imgClass: 'two',
		url: './assets/services/template2.png',
	},
	{
		name: 'Seguros para Empresas',
		description: 'Asegura tu negocio, tus equipos, personal, ...',
		icon: iconStyle(BsShop),
		redir: empresas_wapp,
		link: null,
		imgClass: 'three',
		url: './assets/services/template3.png',
	},	
	{
		name: 'Seguros para Personas',
		description: 'Seguros de Vida, accidentes personales, viajeros, ...',
		icon: iconStyle(AiOutlineUsergroupAdd),
		redir: personas_wapp,
		link: null,
		imgClass: 'four',
		url: './assets/services/template4.png',
	},
	{
		name: 'Asistencia en Siniestros',
		description: 'Nuestro equipo te acompañara en el proceso de tu siniestro.',
		icon: iconStyle(BiSupport),
		redir: null,
		link: '/asistencia-y-siniestros',
		imgClass: 'five',
		url: './assets/services/template5.png',
	},	
	{
		name: 'Seguros de ART',
		description: 'Un respaldo real en el ambito del trabajo, que contribuya a la prevención de riesgos.',
		icon: iconStyle(BsBriefcase),
		redir: art_wapp,
		link: null,
		imgClass: 'six',
		url: './assets/services/template6.png',
	},
	{
		name: 'Mejores Precios',
		description: 'Para que no pagues de más, nuestros seguros son hechos a la medida.',
		icon: iconStyle(BiDollar),
		redir: consultar_wapp,
		link: null,
		imgClass: 'seven',
		url: './assets/services/template7.png',
	},
	{
		name: 'Calidad Asegurada',
		description: 'Trabajamos con empresas de primera línea para ofrecerle lo mejor.',
		icon: iconStyle(SlBadge),
		redir: consultar_wapp,
		link: null,
		imgClass: 'eight',
		url: './assets/services/template8.png',
	},
	{
		name: 'Y mucho más!',
		description: 'Contactanos!.',
		icon: iconStyle(SlOptions),
		redir: consultar_wapp,
		link: null,
		imgClass: 'nine',
		url: null,
	},
];
