import React from "react";
import { ServicesWrapper, ServicesContentBox, ServicesCard } from "./ServicesStyles";
import { servicesData } from "../../data/ServicesData";
import { useNavigate } from "react-router-dom";

const Services = () => {
    let navigate = useNavigate();

    const redirectLogic = (dir, link) => {
		if (dir !== null){
			window.open(dir);
		}
		if (link !== null){
			navigate(link);
		}
        goToTop()
	}

    const goToTop = () => {
		document.body.scrollIntoView();
	};

    return(
        <ServicesWrapper>
            <h1> Nuestras Soluciones </h1>

            <ServicesContentBox>
            { servicesData.map((item, i) => (
                <ServicesCard onClick={ () => redirectLogic(item.redir, item.link)} >
                    <i> {item.icon} </i>
                    <h2> {item.name} </h2>
                    <p> {item.description} </p>
                </ServicesCard>
            ))}

            </ServicesContentBox>

        </ServicesWrapper>
    );
}

    
export default Services;