import React from 'react';
import {useDropzone} from 'react-dropzone';
import { Container, DropZone, DropZoneText } from './FormStyles'

export default function DropZoneFormStep(props) {
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone({accept: {'image/*,.pdf': []}});
  
  return (
    
      <Container {...getRootProps({isFocused, isDragAccept, isDragReject})}>
        <DropZone {...getInputProps()} />
        <DropZoneText>Arrastra la documentación solicitada aquí o haga click para seleccionar los archivos</DropZoneText>
      </Container>
    
  );
}


