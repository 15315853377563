import React from 'react'
import Navbar from '../components/Navbar/Navbar';
import AboutUs from '../components/AboutUs/AboutUs';
import { aboutOne } from '../data/AboutData';

const AboutUsPage = () => {

  return (
    <>
      <Navbar/>
      <AboutUs {...aboutOne}/>
    </>
  )
}

export default AboutUsPage