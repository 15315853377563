import {
	meridional_link,
	mercantil_link,
} from '../ReactGlobalData';

export const productoresOne = {
	reverse: false,
	inverse: false,
	topLine: {
		text: '',
	},
	headline: 'Acceso de Productores',
	description: 'Acceso rápido a compañías',
	buttonLabel: 'Meridional',
	buttonLabel2: 'Mercantil Andina',

	linkTo: null,
	redir: meridional_link,
	redir2: mercantil_link,
	imgStart: 'start',
	img: './assets/svg/Deal.svg',
	start: 'true',
};

/*
export const constructionTwo = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Nos iniciamos en el año 1991',
	},
	headline: "Más de tres décadas en el negocio",
	description: 'Contamos con la experiencia y el respaldo de empresas lideres.',
	buttonLabel: 'Conócenos',

	linkTo: '/quienes-somos',
	redir: null,
	imgStart: 'start',
	img: './assets/svg/Deal.svg',
	start: 'true',
};

export const constructionThree = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Highly reputed brand',
	},
	headline: 'Why us? ',
	description:
		'Our 9 year experience have allowed us to use the most innovative technologies and methodologies',
	buttonLabel: 'View Project',

	linkTo: null,
	redir: null,
	imgStart: '',
	img: './assets/svg/ChartUp.svg',
	start: 'true',
};*/
