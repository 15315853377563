import React from 'react';
import { SiniestrosMenuWrapper, SiniestrosMenuContentBox, SiniestrosMenuCard } from "./SiniestrosMenuStyles";
import { siniestrosmenuData } from "../../data/SiniestrosMenuData";
import { useNavigate } from "react-router-dom";

const SiniestrosMenu = () => {
    let navigate = useNavigate();

    const redirectLogic = (dir, link) => {
		if (dir !== null){
			window.open(dir, '_blank');
		}
		if (link !== null){
			navigate(link);
		}
	}

    return(
        <SiniestrosMenuWrapper>
            <SiniestrosMenuContentBox>
            { siniestrosmenuData.map((item, i) => (
                <SiniestrosMenuCard key={i} onClick={ () => redirectLogic(item.redir, item.link)} >
                    <i> {item.icon} </i>
                    <h2> {item.name} </h2>
                    <p> {item.description} </p>
                </SiniestrosMenuCard>
            ))}
            </SiniestrosMenuContentBox>
        </SiniestrosMenuWrapper>
    );
}

    
export default SiniestrosMenu;