import styled, {keyframes} from 'styled-components';

export const PartnersContainer = styled.div`
  min-height: 0;
  display: grid;
  place-items: center;
`;

export const PartnersSlider = styled.div`
  height: 250px;
  margin: auto;
  position: relative;
  width: 100%;
  display: grid;
  place-items: center;
  overflow: hidden;

  ::before{
    left: 0;
    top: 0;
  }

  ::after{
    right: 0;
    top: 0;
    transform: rotateZ(180deg);
  }
`;

const scroll = keyframes`
  0%{transform: translateX(0);}
  100%{transform: translate(calc(-250px * 28));}
`;

export const PartnersSlideTrack = styled.div`
  display: flex;
  width: calc(250px * 84);
  
  animation-duration: 1ms;

  animation-name: ${scroll};
  animation-duration: 240s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  
  :hover{
    animation-play-state: paused;
  }

`;


export const PartnersSlide = styled.div`
  cursor: pointer;
  height: 250px;
  width: 250px;
  display: flex;
  align-items: center;
  padding: 15px;
  perspective: 100px;
`;

export const PartnersImage = styled.img`
  width: 100%;
  transition: transform 0.5s;

  :hover{
    transform: translateZ(20px);
  }
`;

