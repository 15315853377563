import { React, useEffect} from 'react';
import { Container, Section } from '../../globalStyles';
import {
	LoginRow,
	TextWrapper,
	TopLine,
	Heading,
	LoginButton,
	Subtitle,
	ImgWrapper,
	Img,
	LoginColumn,
} from './LoginStyle.js';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate } from 'react-router-dom';

export const Login = ({
	primary,
	topLine,
	headline,
	description,
	buttonLabel,
	buttonLabel2,
	buttonLabel3,
	buttonLabel4,
	img,
	alt,
	inverse,
	reverse,
}) => {

	const wapp = 'https://api.whatsapp.com/send?phone=%2B5493512050050&text=Hola+me+gustaria+hacerme+socio%3A';

	const initial = { opacity: 0, y: 30 };
	const animation = useAnimation();
	const { loginWithRedirect, logout, isAuthenticated, /*isLoading, user*/} = useAuth0();
	const navigate = useNavigate();
	const { ref, inView } = useInView({ threshold: 0.2 });

	useEffect(() => {
		if (inView) {
			animation.start({
				opacity: 1,
				y: 0,
			});
		}
	}, [inView, animation]);

	const catchLogin = ( action ) =>{
		if(action === 'log'){
			loginWithRedirect();
		}
		if(action === 'unlog'){
			logout({returnTo: window.location.origin });
		}
		if(action === 'partner'){
			window.open(wapp);
		}
		if(action === 'polizas'){
			navigate('/productores');
		}
	}

	return (
		<Section inverse={inverse} ref={ref}>
			<Container>
				<LoginRow reverse={reverse}>
					<LoginColumn>
						<TextWrapper>
							<TopLine
								initial={initial}
								transition={{ delay: 0.3, duration: 0.6 }}
								animate={animation}
							>
								{topLine.text}
							</TopLine>
							<Heading
								initial={initial}
								transition={{ delay: 0.5, duration: 0.6 }}
								animate={animation}
								inverse={+inverse}
							>
								{headline}
							</Heading>
							<Subtitle
								initial={initial}
								transition={{ delay: 0.7, duration: 0.6 }}
								animate={animation}
								inverse={+inverse}
							>
								{description}
							</Subtitle>
							{!isAuthenticated ? <>
								<LoginButton
									initial={initial}
									transition={{ delay: 1, duration: 0.6 }}
									animate={animation}
									inverse={+inverse}
									primary={primary}
									onClick={() => catchLogin('log')}
								>
									{buttonLabel}
								</LoginButton>
								<LoginButton
									initial={initial}
									transition={{ delay: 1, duration: 0.6 }}
									animate={animation}
									inverse={+inverse}
									primary={primary}
									onClick={() => catchLogin('partner')}
								>
									{buttonLabel3}
								</LoginButton>
							</> : <> 
								<LoginButton
									initial={initial}
									transition={{ delay: 1, duration: 0.6 }}
									animate={animation}
									inverse={+inverse}
									primary={primary}
									onClick={() => catchLogin('unlog')}
								>
									{buttonLabel2}
								</LoginButton>
								<LoginButton
									initial={initial}
									transition={{ delay: 1, duration: 0.6 }}
									animate={animation}
									inverse={+inverse}
									primary={primary}
									onClick={() => catchLogin('polizas')}
								>
									{buttonLabel4}
								</LoginButton>
							</>}
						</TextWrapper>
					</LoginColumn>
					<LoginColumn
						initial={initial}
						transition={{ delay: 0.5, duration: 0.6 }}
						animate={animation}
					>
						<ImgWrapper>
							<Img
								src={img}
								alt={alt}
								whileHover={{ rotate: 2, scale: 1.02 }}
								transition={{ duration: 0.5 }}
							/>
						</ImgWrapper>
					</LoginColumn>
				</LoginRow>
			</Container>
		</Section>
	);
};

export default Login;