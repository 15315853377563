import styled from 'styled-components';
import { motion } from 'framer-motion';

export const Card = styled.article`
    padding: 1rem;
    padding-top: 0rem;
    background: rgba(7, 28, 47, 1);
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 15px;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.15);
    display: flex;
    justify-content: left;
    align-items: center;
    width: auto;
    margin: 0rem;
    
  /* Add box shadow to entire card on hover */
  .card:hover {
    box-shadow: 0 1px 35px 0 rgba(0, 0, 0, 0.3);
  }
  
  /* Creates variation of card component in a wide format */
  @media screen and (max-width: 768px) {
    min-width: 300px;
  }
  
  .card__media {
    position: relative;
    width: 300px;
  }
  
  /* Adds transparent overlay over image. */
  .card__media::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
  }
  
  .card__media img {
    display: block;
    max-width: 100%;
    height: auto;
  }
  
  .card__date {
    position: absolute;
    right: 20px;
    top: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #e74c3c;
    color: white;
    font-weight: bold;
  }
  
  .date--day {
    font-size: 20px;
    line-height: 1;
  }
  
  .date--month {
    font-size: 14px;
    line-height: 1;
    text-transform: uppercase;
  }
  
  .card__category {
    position: absolute;
    bottom: 0;
    left: 0;
    font-size: 14px;
    font-weight: bold;
    letter-spacing: 0.06em;
    display: inline-block;
    color: white;
    //background: #e74c3c;
    background: #00358e;
    text-align: center;
    padding: 5px 15px;
    text-transform: uppercase;
    border-radius: 0.5rem;
  }
  
  .card__content {
    padding: 40px 25px;
    color: #999999;
    transition: all 0.3s cubic-bezier(0.37, 0.75, 0.61, 1.05) 0s;
  }
  
  /* Changes padding on wide card */
  .card--wide .card__content {
    padding: 20px 20px;
    width: calc(100% - 300px);
  }
  
  /* By default we hide the excerpt on card long. */
  .card__excerpt {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
  }
  
  /* Removes max-height on wide card */
  .card--block .card__excerpt {
    max-height: none;
  }
  
  /* On hover, we expand the excerpt container to show the text. */
  .card:hover .card__excerpt {
    max-height: 300px;
  }
  
  .card__title {
    color: white;
    margin: 0 0 10px;
    font-size: 20px;
  }
  
  .card__subtitle {
    color: lightgreen;
    font-weight: 300;
  }

  .card__subtitle__risk {
    color: #e74c3c;
    font-weight: 300;
  }
  
  .card__meta {
    color: #ccc;
    font-size: 14px;
  }
`;

export const BodyCard = styled.div `
	background: #f2f5f9;
  	color: #111;
	.container {
	max-width: 1300px;
	width: 100%;
	margin: auto;
	padding: 0 20px;
	}
`;

export const SectionCard = styled.section `
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;

	> h1 {
	justify-self: center;
	margin-bottom: 40px;
	color: #111;
	font-size: 50px;
	line-height: 60px;
	text-align: center;
  }


  .cards {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	width: 100%;
  }
  .card {
	flex: 0 0 calc(33.33% - 20px);
	max-width: calc(33.33% - 20px);
	width: 100%;
	background: #fff;
	padding: 20px;
	margin-bottom: 20px;
	border-radius: 10px;
	border: 2px solid transparent;
	transition: 0.3s;
  }
  .card:hover {
	border-color: #1d39c4;
  }
  
  .card h3 {
	font-size: 24px;
	color: #1d39c4;
	margin-bottom: 20px;
  }
  .card .btn {
	display: flex;
	justify-content: center;
	align-items: center;
	max-width: 120px;
	width: 100%;
	background: #1d39c4;
	border: none;
	outline: none;
	border-radius: 10px;
	height: 40px;
	margin-top: 20px;
	color: #fff;
	cursor: pointer;
	transition: 0.3s;
	border: 1px solid #1d39c4;
  }
  .card .btn:hover {
	color: #1d39c4;
	background: transparent;
  }
  
  @media (max-width: 969px) {
	section{
	  padding: 30px 0px;
	}
	> h1 {
	  font-size: 40px;
	}
	.card {
	  flex: 0 0 calc(50% - 10px);
	  max-width: calc(50% - 10px);
	}
  }
  @media (max-width: 767px) {
	
	.card {
	  flex: 0 0 100%;
	  max-width: 100%;
	}
  }
`;

export const TEST = styled(motion.TEST) `

`;

export const ProductoresRow = styled.div`
	display: flex;
	margin: 0 -15px -15px -15px;
	flex-wrap: wrap;
	align-items: center;
	flex-direction: ${({ reverse }) => (reverse ? 'row-reverse' : 'row')};
	justify-content: space-around;

	@media screen and (max-width: 768px) {
		flex-direction: column-reverse;
	}
`;

export const ProductoresColumn = styled(motion.div)`
	margin-bottom: 15px;
	padding-right: 15px;
	padding-left: 15px;
	flex: 1;
	z-index: 10;
	display: flex;
	flex-direction: column;
	@media screen and (max-width: 768px) {
		max-width: 100% !important;
		flex-basis: 100%;
		justify-content: center;
		align-items: center;
	}
`;

export const TextWrapper = styled.div`
	max-width: 540px;
	padding-top: 0;

	@media screen and (max-width: 768px) {
		padding-bottom: 65px;
		> h1,
		p {
			text-align: center;
		}
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	> img {
		width: 300px;
		margin-left: -3px;
	}
`;

export const ImgWrapper = styled(motion.div)`
	display: flex;
	justify-content: 'flex-end';
	max-height: 700px;
	justify-content: center;
	position: relative;
`;

export const TopLine = styled(motion.div)`
	font-size: 0.9rem;
	line-height: 16px;
	font-weight: 550;
	letter-spacing: 1.4px;
	margin-bottom: 1.3rem;
	color: #979797;
`;

export const Img = styled(motion.img)`
	padding-right: 0;
	border: 0;
	max-width: 100%;
	vertical-align: middle;
	display: inline-block;
	object-fit: cover;
	max-height: 700px;
	z-index: 1;
`;

export const Heading = styled(motion.h2)`
	margin-bottom: 24px;
	font-size: 3rem;
	line-height: 1.1;
	font-weight: 600;
	color: ${({ inverse }) => (inverse ? '#0c4577' : 'white')};

	@media screen and (max-width: 768px) {
		text-align: center;
	}
`;

export const Subtitle = styled(motion.p)`
	max-width: auto;
	margin-bottom: 35px;
	line-height: 24px;
	color: ${({ inverse }) => (inverse ? '#6a6a6a' : 'white')};
`;

export const ProductoresButton = styled(motion.button)`
	height: 3rem;
	padding: 16px 32px;
	font-weight: 700;
	font-size: 0.8rem;
	line-height: 18px;
	letter-spacing: 1.54px;
	text-transform: uppercase;
	cursor: pointer;
	background: none;
	color: ${({ inverse }) => (inverse ? '#0c4577' : 'white')};

	border-radius: 4px;
	white-space: nowrap;
	padding: ${({ big }) => (big ? '12px 64px' : '10px 20px')};
	font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
	outline: none;
	border: 2px solid ${({ inverse }) => (inverse ? '#0c4577' : 'white')};
	cursor: pointer;
	position: relative;
	overflow: hidden;
	
	.active{
		color: ${({ inverse }) => (inverse ? 'white' : 'black')};
	}

	&:before {
		background: ${({ inverse }) => (inverse ? '#0c4577' : 'white')};
		content: '';
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		z-index: -1;
		transition: all 0.6s ease;
		width: 100%;
		height: 0%;
		transform: translate(-50%, -50%) rotate(45deg);
	}

	&:hover:before {
		height: 500%;
	}

	&:hover {
		color: ${({ inverse }) => (inverse ? 'white' : 'black')};
	}
`;