import React from 'react';
import { AiOutlineWhatsApp, AiOutlineCar, AiOutlineForm } from 'react-icons/ai';
import { formSiniestros, pdfSiniestros} from '../ReactGlobalData'
import {
	siniestros_wapp,
} from '../ReactGlobalData';

const iconStyle = (Icon) => <Icon size="3rem" color="#0f0f0f" />;

export const siniestrosmenuData = [
	{
		name: 'Solicitar Asistencia por Whatsapp',
		description: 'Asistencia en siniestros y/o solicitar ayuda.',
		icon: iconStyle(AiOutlineWhatsApp),
		redir: siniestros_wapp,
		link: null,
		imgClass: 'one',
		url: './assets/services/template.png',
	},
	{
		name: 'Denunciar Siniestro Automotor Online',
		description: '',
		icon: iconStyle(AiOutlineCar),
		redir: formSiniestros,
		link: /*"../reportar-siniestro"*/null,
		imgClass: 'two',
		url: './assets/services/template2.png',
	},
	{
		name: 'Denunciar Siniestro por Escrito',
		description: 'PDF',
		icon: iconStyle(AiOutlineForm),
		redir: pdfSiniestros,
		link: null,
		imgClass: 'three',
		url: './assets/services/template5.png',
	}
];
