import { 
	automotor_wapp,
	hogar_wapp,
	personas_wapp,
	empresas_wapp,
	//asistencia_wapp,
	//art_wapp,
	//consultar_wapp
} from '../ReactGlobalData';



export const pricingData = [
	{
		title: 'Automotores',
		price: 'Todo riesgo, Tercero Completos, Responsabilidad Civil, ...',
		features: [
			'Vehículos Particulares', 
			'Vehículos Comerciales',
			'Flotas de Vehículos',
			'Motocicletas Particulares',  
			'Motocicletas Comerciales', 
			'Seguro de Garaje', 
			'y más!'
		],
		wapp: automotor_wapp,
		
	},
	{
		title: 'Hogar',
		price: 'Asegura tu casa, tus equipos electrónicos, ...',
		features: [
			'Incendio',
			'Incendio + Robo + Cristales',
			'Equipos Electrónicos',
			'Responsabilidad Civil Rentistas',
			'y más!'
		],
		wapp: hogar_wapp,
	},
	{
		title: 'Personas',
		price: 'Seguros de Vida, accidentes personales, viajeros, ...',
		features: [
			'Vida',
			'Vida + Invalidez',
			'Vida + Invalidez + Asistencia Médica Prestacional',
			'Vida + Invalidez + Asistencia Médica por Reintegro',
			'Accidentes Personales',
			'Seguro de Viajeros',
			'Mala Praxis',
			'Responsabilidad Civil Profesional',
			'y más!'
		],
		wapp: personas_wapp,
	},
	{
		title: 'Empresas',
		price: 'Asegura tu negocio, tus equipos, personal, ...',
		features: [
			'ART',
			'Vida obligatorio y Convenios',
			'Todo Riesgo Operativo (TRO)',
			'Integrales de Comercio',
			'Transporte',
			'Caución', 
			'y más!'
		],
		wapp: empresas_wapp,
	},
];
