import { Container } from '../../globalStyles';
import { Card, ProductoresButton, Heading, Subtitle, TextWrapper } from './CardStyles'; 
import { SearchBarContainer, SearchBarSelect, SearchBarInput, SearchBarOption, SearchBarButton } from './SearchBarStyles'; 
import { React, useEffect, useState} from 'react';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { useAuth0 } from '@auth0/auth0-react';
//import { mercantil_link, meridional_link } from '../../ReactGlobalData';
import base64 from 'base-64'

async function getData(field, query , page){
    if( page === null){
        page = 0;
    }

    let username = "octaviogarcia1337@gmail.com"
    let token = "ce1d44c8-ca74-4101-b6d8-7afd1216553d"

    let dev = "https://sheetlabs.com/AG/DemoGrupoAG?";

    //return await fetch( tester + field + "=*" + query + "*&_offset=" + page*100 + "&_limit=100", {
    //return await fetch( dev + field + "=*" + query + "&_limit=100", {

    return await fetch( dev + field + "=*" + query + "&_limit=100", {
        method: 'GET',
        headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Basic ' + base64.encode(username + ":" + token)
        }
    }).then(response => response.json())   
}

function parseField(field){
    if (field !== undefined){
      return field
    }
    return "Not available"
}


const redirectLogic = (redlink, inslink, company) => {
    if (redlink !== null){
        window.open(redlink);
    }
    if (inslink !== null){
        window.open(inslink,"_self")
    }
    if (company !== null){
        if (company === 'Mercantil andina Seguros'){
            window.open(/*mercantil_link*/"https://agsegurosweb.com", '_blank');
        }
        if (company === 'Meridional'){
            window.open(/*meridional_link*/"https://agsegurosweb.com", '_blank');
        }
    }else{
        window.open("https://drive.google.com/file/d/13O0WZQHB31H1w1nLJnXdFymnQ7YJv1dX/view?usp=sharing", '_blank');
    }

}


function showCards(cards){
	const initial = { opacity: 1, y: 30 };

    return cards.map((card, i) =>
        <div obj={card} key={i} style={{ backgroundColor: 'rgba(7, 28, 47, 0)', margin: '1rem'}}>           
            <Card className="card card--wide" >
                <div className="card__content" >
                    <header className="card__header">
                        <h2 className="card__title"> {parseField(card.ASEGURADO /*card.domain*/)} </h2>
                        <div className="card__subtitle"> {parseField(card.BIEN /*registeredto*/ )} </div>
                        <br/>
                        <div className="card__subtitle__risk"> {parseField(card.RIESGO /*rank*/)} </div>
                    </header>

                    <footer className="card__meta" role="contentinfo">
                        <span className="card__post-date"> Vigencia: {/*parseField(card.vigencia)*/} {parseField(card.DESDE)} - {parseField(card.HASTA)} </span>
                        <br/>
                        <span className="card__comments"> Póliza: {parseField(card.POLIZA)} </span>
                        <br/>
                        <span className="card__comments"> Productor: {parseField(card.PRODUCTOR)} </span>
                        <br/>
                        <span className="card__comments"> Suma: $ {parseField(card.SUMA)} </span>
                        <br/>
                        <span className="card__comments"> Premio: $ {parseField(card.PREMIO)} </span>
                    </footer>
                    <TextWrapper>
                        <ProductoresButton 
                            initial={initial}
                            transition={{ delay: 1, duration: 0.6 }}
                            onClick={() => redirectLogic(null, null, parseField(card.ENTIDAD))}
                        >
                            {parseField(card.ENTIDAD) === 'Mercantil andina Seguros' ? 'ASEGURADORA' : 'ASEGURADORA'}
                        </ProductoresButton>                    
                        <ProductoresButton 
                            initial={initial}
                            transition={{ delay: 1, duration: 0.6 }}
                            onClick={() => redirectLogic(null, null, null)}
                        >
                            {"Descargar"}
                        </ProductoresButton>
                    </TextWrapper>
                    <br/>
                    <br/>
                </div>
            </Card>                           
        </div>
    )
}
  

function Cards(){
    const [items, setItems] = useState([]);
    const [failedSearch, setFailedSearch] = useState(false);
    const [query, setQuery] = useState("");
    const [field, setField] = useState('ASEGURADO');
    const {isAuthenticated} = useAuth0();

    const initial = { opacity: 1, y: 30 };
	const animation = useAnimation();

	const { inView } = useInView({ threshold: 0.2 });

	useEffect(() => {
		if (inView) {
			animation.start({
				opacity: 1,
				y: 0,
			});
		}
	}, [inView, animation]);

    async function searchQuery(field, query){
        if(query === ""){
          query = "*"
        }
        await getData(field, query, localStorage.getItem("page")).then(response=>{
          if(response != null){
            if(response.length > 0){
              setItems(response)
              setFailedSearch(false)
            }else{
              setItems([])
              setFailedSearch(true)
            }
          }
          else{
            setFailedSearch(false)
          }
        })
    }
    
    function searchDelete(field, query){
        searchQuery(field, query);
    }

    const renderFailedSearch = (
        <Container style={{textAlign: 'center'}}>
            <Heading
                inverse={'+inverse'}
                primary={'primary'}
                initial={initial}
                transition={{ delay: 0.5, duration: 0.6 }}
                animate={animation}
            >
                {"No se han encontrado resultados."}
            </Heading>
            <Subtitle style={{textAlign: 'center'}}
                inverse={'+inverse'}
                primary={'primary'}
                initial={initial}
                transition={{ delay: 0.7, duration: 0.6 }}
                animate={animation}
            >
                {"Pruebe realizar otra busqueda."}
            </Subtitle>
        </Container>
    )
    
    const handleSelectChange = (e) => {
        setField(e.target.value);
    };

    return(
        <>
        {!isAuthenticated ? 
            <Container style={{textAlign: 'center'}}>
                <Heading
                    inverse={'+inverse'}
                    primary={'primary'}
                    initial={initial}
                    transition={{ delay: 0.5, duration: 0.6 }}
                    animate={animation}
                >
                    {"No se pudo validar su sesión."}
                </Heading>
                <Subtitle style={{textAlign: 'center'}}
                    inverse={'+inverse'}
                    primary={'primary'}
                    initial={initial}
                    transition={{ delay: 0.7, duration: 0.6 }}
                    animate={animation}
                >
                    {"Pruebe recargar la página o vuelva a iniciar sesión."}
                </Subtitle>
                <ProductoresButton 
                    inverse={'+inverse'}
                    primary={'primary'}
                    initial={initial}
                    transition={{ delay: 1, duration: 0.6 }}
                    animate={animation}
                    onClick={() => redirectLogic(null, '/login', null)}
                >
                    {"Iniciar sesión"}
                </ProductoresButton>
                <ProductoresButton 
                    inverse={'+inverse'}
                    primary={'primary'}
                    initial={initial}
                    transition={{ delay: 1, duration: 0.6 }}
                    animate={animation}
                    onClick={() => {window.location.reload(true)}}
                >
                    {"Recargar Página"}
                </ProductoresButton>
                <br/><br/><br/><br/><br/>
            </Container>
        : 
        <>
        <SearchBarContainer>
                <SearchBarSelect onChange={handleSelectChange} name="ids" id="ids">
                    <SearchBarOption value="ASEGURADO">Asegurado</SearchBarOption>
                    <SearchBarOption value="BIEN">Bien</SearchBarOption>
                    <SearchBarOption value="RIESGO">Riesgo</SearchBarOption>
                    <SearchBarOption value="POLIZA">Póliza</SearchBarOption>
                    <SearchBarOption value="PRODUCTOR">Productor</SearchBarOption>
                    <SearchBarOption value="SUMA">Suma</SearchBarOption>
                    <SearchBarOption value="PREMIO">Premio</SearchBarOption>
                    {/* <option value="domain">TESTING</option> */}
                </SearchBarSelect>
                <SearchBarInput
                    type='text'
                    id='searchInput'
                    placeholder='Buscar póliza...'
                    onChange={ (e) => setQuery(e.target.value)}
                    value={query}
                    onKeyDown={ (e) => e.key === "Enter" ? searchDelete(field, query) : void(0)}
                />
        </SearchBarContainer>
        <SearchBarContainer>
            <SearchBarButton 
                inverse={'+inverse'} 
                primary={'primary'} 
                transition={{ delay: 1, duration: 0.6 }} 
                onClick={() => searchDelete(field, query)}
            >
                {' Buscar '}
            </SearchBarButton>
        </SearchBarContainer>
        <div style={{margin: '1rem', paddingBottom: '3rem'}}>
            {failedSearch ? renderFailedSearch : void(0)}
            {items.length > 0 || failedSearch ? showCards(items) : renderFailedSearch}
        </div>
        <br/>
        </>
        }
        </>
    );
}

export default Cards;
