import React from 'react'
import Login from '../components/Login/Login';
import { loginOne } from '../data/LoginData';

const LoginPage = () => {

  return (
    <>
      <Login {...loginOne}/>
    </>
  )
}

export default LoginPage