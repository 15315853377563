import React from 'react';
import { BsGeoAlt, BsInstagram, BsWhatsapp } from "react-icons/bs";

import { consultar_wapp, googlemaps, instagram } from '../ReactGlobalData';

const iconStyle = (Icon) => <Icon />;

export const footerSocialData = [
	{
		name: 'Instagram',
		icon: iconStyle(BsInstagram),
		link: instagram,
	},
	{
		name: 'Whatsapp',
		icon: iconStyle(BsWhatsapp),
		link: consultar_wapp,
	},
	{
		name: 'Google Maps',
		icon: iconStyle(BsGeoAlt),
		link: googlemaps,
	},
];

export const footerData = [
	{
		title: 'Principal',
		links: ['Acceso PAS','Asistencia y Siniestros', 'Contáctanos'],
		redir: ['/productores','/asistencia-y-siniestros', consultar_wapp ],
	},
	{
		title: 'Legal',
		links: ['Creditos', 'Políticas de Privacidad','Términos y Condiciones de Uso'],
		redir: ['/creditos', '/politicas-de-privacidad','/descargo-de-responsabilidad'],
	},
	{
		title: 'Páginas',
		links: ['Principal','Productos', 'Quienes Somos'],
		redir: ['/','/productos', '/quienes-somos'],
	},
];
