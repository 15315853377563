import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

export default function ThirdFormStep() {
  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '100%'},
      }}
      noValidate
      autoComplete="off"
    >

      <Box sx={{ flexGrow: 1 }}>
        <Grid container alignItems="center" columns={10} spacing={4}>
          <Grid item alignItems="center" xs={10} sx={{
            marginTop:"50px",
            width:"100%"}}>
            <TextField
              id="outlined-textarea"
              label="Marca y modelo del vehículo del tercero"
              placeholder="Escriba la marca y modelo aquí"
              multiline
              fullWidth
            />
          </Grid>
          <Grid item alignItems="center" xs={5}>
            <TextField
              id="outlined-textarea"
              label="Dominio/Patente del vehículo del tercero"
              placeholder="AAA-123 / AA-123-BB"
              multiline
            />
          </Grid>
          <Grid item alignItems="center" xs={5}>
            <TextField
              id="outlined-textarea"
              label="Aseguradora del tercero"
              placeholder="Escriba el nombre de la aseguradora aquí"
              multiline
            />
          </Grid>
          <Grid item alignItems="center" xs={10}>
            <TextField
              id="outlined-textarea"
              label="Nombre del conductor tercero"
              placeholder="Escriba el nombre del conductor aquí"
              multiline
            />
          </Grid>
          <Grid item alignItems="center" xs={5}>
            <TextField
              id="outlined-textarea"
              label="DNI del conductor tercero"
              placeholder="Escriba el nombre del conductor aquí"
              multiline
            />
          </Grid>
          <Grid item alignItems="center" xs={5}>
            <TextField
              id="outlined-textarea"
              label="Teléfono de contacto del tercero"
              placeholder="Escriba el télefono del tercero aquí"
              multiline
            />
          </Grid>
          
          <Grid item xs={10}>
            <TextField
              id="outlined-multiline-static"
              label="Declarar daños del tercero"
              multiline
              rows={10}
              placeholder='Ejemplo: Puerte trasera del lado del acompañante'
              defaultValue=""
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}