import {React, useState} from 'react';
import {FaTimes} from 'react-icons/fa';
import {CgMenuRight} from 'react-icons/cg';
import {IconContext} from 'react-icons';
import {Nav, NavbarContainer, NavLogo, NavIcon, MobileIcon, NavMenu, NavLinks, NavItem} from './NavBarStyles.js';
import {useLocation, useNavigate} from 'react-router-dom';
import {data} from '../../data/NavbarData';

const Navbar = () => {
    const [show, setShow] = useState(false);
    
    let navigate = useNavigate();
    let location = useLocation();

    const handleClick = () => {
        if (show === true){
            document.querySelector("#nav-container").style.position="absolute";
            document.querySelector("#nav-container").style.backgroundColor="transparent";
        }else{
            document.querySelector("#nav-container").style.position="sticky";
            document.querySelector("#nav-container").style.backgroundColor="#071c2f";
        }
        
        setShow(!show);
    };

    const scrollTo = (id) => {
        const element = document.getElementById(id)

        element.scrollIntoView({
            behavior: 'smooth',
        })
    }

    const closeMobileMenu = (to, id, redir) => {


        if (id && location.pathname==='/') {
            scrollTo(id);
        }

        document.querySelector("#nav-container").style.position="absolute";
        document.querySelector("#nav-container").style.backgroundColor="transparent";
        navigate(to);
        if (redir !== null){
            window.open(redir)
            setShow(false)
        }
        
        setShow(false);
    }

    return (
        <IconContext.Provider value={{color:"#fff"}}>
            <Nav id="nav-container">
                <NavbarContainer>
                    <NavLogo to="/">
                        <NavIcon src="./assets/logo.png" alt="logo" />
                            {/* Grupo AG */}
                    </NavLogo>
                    <MobileIcon onClick={handleClick}> 
                        { show ? <FaTimes/> : <CgMenuRight/> } 
                    </MobileIcon>
                    <NavMenu show={show}>
                        {data.map((el, index) => (
                            <NavItem key={index}>
                                <NavLinks onClick={() => closeMobileMenu(el.to, el.id, el.redir)}> {el.text} </NavLinks>
                            </NavItem>
                        ))}
                    </NavMenu>
                </NavbarContainer>
            </Nav>
        </IconContext.Provider>
    )
}

export default Navbar