import React from 'react'
import PrivacyPolicies from '../components/PrivacyPolicies/PrivacyPolicies';
import { privacyOne } from '../data/PrivacyPoliciesData';

const PrivacyPoliciesPage = () => {

  return (
    <>
      <PrivacyPolicies {...privacyOne }/>
    </>
  )
}

export default PrivacyPoliciesPage