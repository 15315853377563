import React from 'react'
import Productores from '../components/Productores/Productores';
import { productoresOne } from '../data/ProductoresData';
import Cards from '../components/Card/Card';

const ProductoresPage = () => {

  return (
    <>
      <Productores {...productoresOne}/>
      <Cards/> 
    </>
  )
}

export default ProductoresPage