import React from 'react'
import Construction from '../components/Construction/Construction';
import { constructionOne } from '../data/ConstructionData';

const ConstructionPage = () => {

  return (
    <>
      <Construction {...constructionOne}/>
    </>
  )
}

export default ConstructionPage