import {React, useEffect} from 'react';
import { Container, Section } from '../../globalStyles';
import {
	AboutUsRow, 
	TextWrapper, 
	TopLine, 
	Heading, 
	AboutUsButton, 
	Subtitle, 
	//ImgWrapper, 
	//Img, 
	AboutUsColumn,
} from './AboutUsStyle.js';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom';

export const AboutUs = ({
	primary,
	topLine,
	headline,
	description0,
	description1,
	description2,
	description3,
	buttonLabel,
	//img,
	//alt,
	inverse,
	reverse,
	redir,
	linkTo,
}) => {

	let navigate = useNavigate();
	const initial = { opacity: 0, y: 30 };
	const animation = useAnimation();

	const { ref, inView } = useInView({ threshold: 0.2 });

	useEffect(() => {
		if (inView) {
			animation.start({
				opacity: 1,
				y: 0,
			});
		}
	}, [inView, animation]);


	const redirectLogic = (redlink, inslink) => {
		if (redlink !== null){
			window.open(redlink);
		}
		if (inslink !== null){
			navigate(inslink);
		}
	}

	return (
		<Section inverse={inverse} ref={ref}>
			<Container>
				<AboutUsRow reverse={reverse}>
					<AboutUsColumn>
						<TextWrapper>
							<TopLine
								initial={initial}
								transition={{ delay: 0.3, duration: 0.6 }}
								animate={animation}
							>
								{topLine.text}
							</TopLine>
							<Heading
								initial={initial}
								transition={{ delay: 0.5, duration: 0.6 }}
								animate={animation}
								inverse={+inverse}
							>
								{headline}
							</Heading>
							<Subtitle
								initial={initial}
								transition={{ delay: 0.7, duration: 0.6 }}
								animate={animation}
								inverse={+inverse}
							>
								{description0}<br/>
								<br/>{description1}<br/>
								<br/>{description2}<br/>
								<br/>{description3}<br/>

							</Subtitle>
							<AboutUsButton 
								initial={initial}
								transition={{ delay: 1, duration: 0.6 }}
								animate={animation}
								inverse={+inverse}
								primary={primary}
								onClick={() => redirectLogic(redir, linkTo)}
							>
								{buttonLabel}
							</AboutUsButton>
						</TextWrapper>
					</AboutUsColumn>
					{/* <AboutUsColumn>
						<ImgWrapper>
							<Img
								src={img}
								alt={alt}
								whileHover={{ rotate: 2, scale: 1.02 }}
								transition={{ duration: 0.5 }}
							/>
						</ImgWrapper>
					</AboutUsColumn> */}
				</AboutUsRow>
			</Container>
		</Section>
	);
};

export default AboutUs;