import FormStepper from "../MaterialComponents/FormStepper";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";

const Form = () => {


    return(
        <div style={{background:"rgb(7, 28, 47)", paddingTop:"10rem" }}> 
            <Container>
                <Box sx={{ bgcolor: 'white'}} style={{padding: "30px 30px 30px 30px", borderRadius:"30px"}}>
                    <FormStepper/>  
                </Box>
            </Container>
        </div>
    );
};

export default Form;