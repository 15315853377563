import React from "react";
import {PartnersSlider, PartnersSlideTrack, PartnersSlide, PartnersImage, PartnersContainer} from "./PartnersBannerStyles";
import { 
    asociart_home, 
    berkley_home,
    beneficio_home,
    galeno_home,
    integrity_home, 
    meridional_home, 
    mercantil_home, 
    prevencion_home, 
    rivadavia_home, 
    sancristobal_home, 
    sancor_home, 
    sura_home,     
    swissmedical_home, 
    zurich_home,
} from './../../ReactGlobalData';

const InsuranceBanner = () => {
    let path = "./assets/insurance_logos/"

    const redirectLogic = (link) => {
		if (link !== null){
			window.open(link,'_blank');
		}
    }

    return(
        <PartnersContainer>
            <PartnersSlider>
                <PartnersSlideTrack>
                    <PartnersSlide>
                        <PartnersImage src={path + "asociart.png"} onClick={() => redirectLogic(asociart_home)} alt="Asociart logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "beneficio.png"} onClick={() => redirectLogic(berkley_home)} alt="beneficio logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "berkley.png"} onClick={() => redirectLogic(beneficio_home)} alt="Berkley logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "galeno.png"} onClick={() => redirectLogic(galeno_home)} alt="Galeno logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "integrity.png"} onClick={() => redirectLogic(integrity_home)} alt="Integrity logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "mercantilandina.png"} onClick={() => redirectLogic(mercantil_home)} alt="Mercantil Andina logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "meridional.png"} onClick={() => redirectLogic(meridional_home)} alt="Meridional logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "prevencion.png"} onClick={() => redirectLogic(prevencion_home)} alt="Prevencion logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "rivadavia.png"} onClick={() => redirectLogic(rivadavia_home)} alt="Rivadavia logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "sancor.png"} onClick={() => redirectLogic(sancor_home)} alt="Sancor logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "sancristobal.png"} onClick={() => redirectLogic(sancristobal_home)} alt="San Cristobal logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "sura.png"} onClick={() => redirectLogic(sura_home)}alt="Sura logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "swissmedical.png"} onClick={() => redirectLogic(swissmedical_home)} alt="Swiss Medical logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "zurich.png"} onClick={() => redirectLogic(zurich_home)} alt="Zurich logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "asociart.png"} onClick={() => redirectLogic(asociart_home)} alt="Asociart logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "beneficio.png"} onClick={() => redirectLogic(berkley_home)} alt="beneficio logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "berkley.png"} onClick={() => redirectLogic(beneficio_home)} alt="Berkley logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "galeno.png"} onClick={() => redirectLogic(galeno_home)} alt="Galeno logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "integrity.png"} onClick={() => redirectLogic(integrity_home)} alt="Integrity logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "mercantilandina.png"} onClick={() => redirectLogic(mercantil_home)} alt="Mercantil Andina logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "meridional.png"} onClick={() => redirectLogic(meridional_home)} alt="Meridional logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "prevencion.png"} onClick={() => redirectLogic(prevencion_home)} alt="Prevencion logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "rivadavia.png"} onClick={() => redirectLogic(rivadavia_home)} alt="Rivadavia logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "sancor.png"} onClick={() => redirectLogic(sancor_home)} alt="Sancor logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "sancristobal.png"} onClick={() => redirectLogic(sancristobal_home)} alt="San Cristobal logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "sura.png"} onClick={() => redirectLogic(sura_home)}alt="Sura logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "swissmedical.png"} onClick={() => redirectLogic(swissmedical_home)} alt="Swiss Medical logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "zurich.png"} onClick={() => redirectLogic(zurich_home)} alt="Zurich logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "asociart.png"} onClick={() => redirectLogic(asociart_home)} alt="Asociart logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "beneficio.png"} onClick={() => redirectLogic(berkley_home)} alt="beneficio logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "berkley.png"} onClick={() => redirectLogic(beneficio_home)} alt="Berkley logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "galeno.png"} onClick={() => redirectLogic(galeno_home)} alt="Galeno logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "integrity.png"} onClick={() => redirectLogic(integrity_home)} alt="Integrity logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "mercantilandina.png"} onClick={() => redirectLogic(mercantil_home)} alt="Mercantil Andina logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "meridional.png"} onClick={() => redirectLogic(meridional_home)} alt="Meridional logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "prevencion.png"} onClick={() => redirectLogic(prevencion_home)} alt="Prevencion logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "rivadavia.png"} onClick={() => redirectLogic(rivadavia_home)} alt="Rivadavia logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "sancor.png"} onClick={() => redirectLogic(sancor_home)} alt="Sancor logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "sancristobal.png"} onClick={() => redirectLogic(sancristobal_home)} alt="San Cristobal logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "sura.png"} onClick={() => redirectLogic(sura_home)}alt="Sura logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "swissmedical.png"} onClick={() => redirectLogic(swissmedical_home)} alt="Swiss Medical logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "zurich.png"} onClick={() => redirectLogic(zurich_home)} alt="Zurich logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "asociart.png"} onClick={() => redirectLogic(asociart_home)} alt="Asociart logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "beneficio.png"} onClick={() => redirectLogic(berkley_home)} alt="beneficio logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "berkley.png"} onClick={() => redirectLogic(beneficio_home)} alt="Berkley logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "galeno.png"} onClick={() => redirectLogic(galeno_home)} alt="Galeno logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "integrity.png"} onClick={() => redirectLogic(integrity_home)} alt="Integrity logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "mercantilandina.png"} onClick={() => redirectLogic(mercantil_home)} alt="Mercantil Andina logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "meridional.png"} onClick={() => redirectLogic(meridional_home)} alt="Meridional logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "prevencion.png"} onClick={() => redirectLogic(prevencion_home)} alt="Prevencion logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "rivadavia.png"} onClick={() => redirectLogic(rivadavia_home)} alt="Rivadavia logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "sancor.png"} onClick={() => redirectLogic(sancor_home)} alt="Sancor logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "sancristobal.png"} onClick={() => redirectLogic(sancristobal_home)} alt="San Cristobal logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "sura.png"} onClick={() => redirectLogic(sura_home)}alt="Sura logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "swissmedical.png"} onClick={() => redirectLogic(swissmedical_home)} alt="Swiss Medical logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "zurich.png"} onClick={() => redirectLogic(zurich_home)} alt="Zurich logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "asociart.png"} onClick={() => redirectLogic(asociart_home)} alt="Asociart logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "beneficio.png"} onClick={() => redirectLogic(berkley_home)} alt="beneficio logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "berkley.png"} onClick={() => redirectLogic(beneficio_home)} alt="Berkley logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "galeno.png"} onClick={() => redirectLogic(galeno_home)} alt="Galeno logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "integrity.png"} onClick={() => redirectLogic(integrity_home)} alt="Integrity logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "mercantilandina.png"} onClick={() => redirectLogic(mercantil_home)} alt="Mercantil Andina logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "meridional.png"} onClick={() => redirectLogic(meridional_home)} alt="Meridional logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "prevencion.png"} onClick={() => redirectLogic(prevencion_home)} alt="Prevencion logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "rivadavia.png"} onClick={() => redirectLogic(rivadavia_home)} alt="Rivadavia logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "sancor.png"} onClick={() => redirectLogic(sancor_home)} alt="Sancor logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "sancristobal.png"} onClick={() => redirectLogic(sancristobal_home)} alt="San Cristobal logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "sura.png"} onClick={() => redirectLogic(sura_home)}alt="Sura logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "swissmedical.png"} onClick={() => redirectLogic(swissmedical_home)} alt="Swiss Medical logo"/>
                    </PartnersSlide>
                    <PartnersSlide>
                        <PartnersImage src={path + "zurich.png"} onClick={() => redirectLogic(zurich_home)} alt="Zurich logo"/>
                    </PartnersSlide>
                </PartnersSlideTrack>
            </PartnersSlider>
        </PartnersContainer>
    );
}

    
export default InsuranceBanner;