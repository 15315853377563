import React from 'react';
import Siniestros from '../components/Siniestros/Siniestros';
import SiniestrosMenu from '../components/SiniestrosMenu/SiniestrosMenu';
import { siniestrosOne } from '../data/SiniestrosData';

const SiniestrosPage = () => {

  return (
    <>
      <Siniestros {...siniestrosOne}/>
      <SiniestrosMenu/>
    </>
  )
}

export default SiniestrosPage