import React from 'react'
import Hero from '../components/Hero/Hero';
import Services from '../components/Services/Services';
import Partners from '../components/PartnersBanner/PartnersBanner';
import Content from '../components/Content/Content';
import Carousel from '../components/Carousel/Carousel';
import { heroOne, heroTwo } from '../data/HeroData';

const Home = () => {

  return (
    <>
      <Hero/>
      <Partners/>
      <Services/>
      <Content {...heroOne}/>
      <Content {...heroTwo}/> 
      <Carousel/>
    </>

  )
}

export default Home;