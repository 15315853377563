import React from 'react'
import Credits from '../components/Credits/Credits';
import { creditsOne} from '../data/CreditsData';

const CreditsPage = () => {

  
  return (
    <>
      <Credits {...creditsOne}/>
    </>
  )
}

export default CreditsPage