import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDateTimePicker } from '@mui/x-date-pickers/MobileDateTimePicker';

export default function ClientFormStep() {
  
  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '100%'},
      }}
      noValidate
      autoComplete="off"
    >
      <Box sx={{ flexGrow: 1 }}>
        <Grid container alignItems="center" columns={10} spacing={4}>
          <Grid item alignItems="center" xs={10} sx={{
            marginTop:"50px",
            width:"100%"}}>
            <TextField
              id="outlined-textarea"
              label="Nombre del asegurado/tomador"
              placeholder="Escriba el nombre aquí"
              multiline
              fullWidth
            />
          </Grid>
          <Grid item alignItems="center" xs={10} sx={{width:"100%"}}>
            <TextField
              id="outlined-textarea"
              label="Nombre del conductor del vehículo asegurado"
              placeholder="Escriba el nombre aquí"
              multiline
              fullWidth
            />
          </Grid>
          <Grid item alignItems="center" xs={10}>
            <TextField
              id="outlined-textarea"
              label="Email del asegurado/tomador"
              placeholder="email@email.com"
              multiline
              fullWidth
            />
          </Grid>
          <Grid item alignItems="center" xs={5}>
            <TextField
              id="outlined-textarea"
              label="Dominio/Patente del vehículo segurado"
              placeholder="AAA-123 / AA-123-BB"
              multiline
            />
          </Grid>

          <Grid item xs={5} components={['MobileTimePicker']}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
              <MobileDateTimePicker 
                label={"Fecha y hora del Siniestro"}
                orientation={'portrait'}
                ampm={false}
                ampmInClock={false}
                disableFuture={true}
                />
          </LocalizationProvider>          
          </Grid>
          
          <Grid item xs={10}>
            <TextField
              id="outlined-multiline-static"
              label="Declarar daños propios"
              multiline
              rows={10}
              placeholder='Ejemplo: Puerte trasera del lado del acompañante'
              defaultValue=""
            />
          </Grid>

        </Grid>
      </Box>
    </Box>
  );
}