import {React, useEffect} from 'react';
import { Container, Section } from '../../globalStyles';
import {
	PrivacyPoliciesRow, 
	TextWrapper, 
	TopLine, 
	Heading, 
	PrivacyPoliciesButton, 
	Subtitle, 
} from './PrivacyPoliciesStyle.js';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
export const PrivacyPolicies = ({
	primary,
	topLine,
	headline,
	subtitle,
	buttonLabel,
	inverse,
	reverse,
	redir,
	linkTo,
}) => {

	let navigate = useNavigate();
	const initial = { opacity: 1, y: 30 };
	const animation = useAnimation();

	const { ref, inView } = useInView({ threshold: 0.2 });

	useEffect(() => {
		if (inView) {
			animation.start({
				opacity: 1,
				y: 0,
			});
		}
	}, [inView, animation]);

	const redirectLogic = (redlink, inslink) => {
		if (redlink !== null){
			window.open(redlink);
		}
		if (inslink !== null){
			navigate(inslink);
		}
	}

	return (
		<Section inverse={inverse} ref={ref}>
			<Container>
				<PrivacyPoliciesRow reverse={reverse}>
					<TextWrapper>
						<TopLine
							initial={initial}
							transition={{ delay: 0.3, duration: 0.6 }}
							animate={animation}
						>
							{topLine.text}
						</TopLine>
						<Heading
							initial={initial}
							transition={{ delay: 0.5, duration: 0.6 }}
							animate={animation}
							inverse={+inverse}
						>
							{headline}
						</Heading>
						<Subtitle
							initial={initial}
							transition={{ delay: 0.7, duration: 0.6 }}
							animate={animation}
							inverse={+inverse}
						>
							{subtitle}
						</Subtitle>
					</TextWrapper>
				</PrivacyPoliciesRow>
			 	<TextWrapper>
						<Subtitle
								initial={initial}
								transition={{ delay: 0.2, duration: 0.2 }}
								animate={animation}
								inverse={+inverse}
							>
								
							<br/>
								Esta Política de privacidad describe cómo se recopila, utiliza y comparte su información personal cuando 
								visita o hace una compra en agsegurosweb.com (denominado en lo sucesivo el “Sitio”).
							<br/>
							
							<br/>
							<b>INFORMACIÓN PERSONAL QUE RECOPILAMOS</b>
							<br/><br/>
								Cuando visita el Sitio, recopilamos automáticamente cierta información sobre su dispositivo, incluida 
								información sobre su navegador web, dirección IP, zona horaria y algunas de las cookies que están 
								instaladas en su dispositivo. Además, a medida que navega por el Sitio, recopilamos información 
								sobre las páginas web individuales o los productos que ve, las páginas web o los términos de búsqueda 
								que lo remitieron al Sitio e información sobre cómo interactúa usted con el Sitio. Nos referimos a esta 
								información recopilada automáticamente como “Información del dispositivo”.
							
							<br/><br/>
								Recopilamos Información del dispositivo mediante el uso de las siguientes tecnologías:
							<br/><br/>
							
							<li>
								Los “Archivos de registro” rastrean las acciones que ocurren en el Sitio y recopilan datos, incluyendo 
								su dirección IP, tipo de navegador, proveedor de servicio de Internet, páginas de referencia/salida y 
								marcas de fecha/horario.
							</li>
							<li>
								Las “balizas web”, las “etiquetas” y los “píxeles” son archivos electrónicos utilizados para registrar 
								información sobre cómo navega usted por el Sitio.
							</li>

							<br/>
								Además, cuando hace una compra o intenta hacer una compra a través del Sitio, recopilamos cierta información 
								de usted, entre la que se incluye su nombre, dirección de facturación, dirección de envío, información de pago 
								(incluidos los números de la tarjeta de crédito), dirección de correo electrónico y número de teléfono.  
								Nos referimos a esta información como “Información del pedido”.
							<br/><br/>
								Cuando hablamos de “Información personal” en la presente Política de privacidad, nos referimos tanto a la Información 
								del dispositivo como a la Información del pedido.
							<br/><br/>

							<b>¿CÓMO UTILIZAMOS SU INFORMACIÓN PERSONAL?</b>
							<br/>
								Usamos la Información del pedido que recopilamos en general para preparar los pedidos realizados a través del Sitio 
								(incluido el procesamiento de su información de pago, la organización de los envíos y la entrega de facturas y/o confirmaciones 
								de pedido).  Además, utilizamos esta Información del pedido para: comunicarnos con usted; examinar nuestros pedidos en busca 
								de fraudes o riesgos potenciales; y cuando de acuerdo con las preferencias que usted compartió con nosotros, le proporcionamos 
								información o publicidad relacionada con nuestros productos o servicios.
							<br/><br/>
								Utilizamos la Información del dispositivo que recopilamos para ayudarnos a detectar posibles riesgos y fraudes (en particular, su 
								dirección IP) y, en general, para mejorar y optimizar nuestro Sitio (por ejemplo, al generar informes y estadísticas sobre cómo 
								nuestros clientes navegan e interactúan con el Sitio y para evaluar el éxito de nuestras campañas publicitarias y de marketing).
								COMPARTIR SU INFORMACIÓN PERSONAL
							<br/><br/>
								Compartimos su Información personal con terceros para que nos ayuden a utilizar su Información personal, tal como se describió 
								anteriormente. También utilizamos Google Analytics para ayudarnos a comprender cómo usan nuestros clientes el Sitio. Puede obtener 
								más información sobre cómo Google utiliza su Información personal aquí: 
								<a href='https://www.google.com/intl/es/policies/privacy/' style={{cursor: "pointer", color: "lightblue", textDecoration: "none"}}> Google Policies. </a>
								Puede darse de baja de Google Analytics aquí: 
								<a href='https://tools.google.com/dlpage/gaoptout' style={{cursor: "pointer", color: "lightblue", textDecoration: "none"}}> Google Analytics. </a>
							<br/><br/>
								Finalmente, también podemos compartir su Información personal para cumplir con las leyes y regulaciones aplicables, para 
								responder a una citación, orden de registro u otra solicitud legal de información que recibamos, o para proteger nuestros derechos.
							<br/>
							
							<br/>
							<b>PUBLICIDAD ORIENTADA POR EL COMPORTAMIENTO</b>
							<br/>
								Como se describió anteriormente, utilizamos su Información personal para proporcionarle anuncios publicitarios 
								dirigidos o comunicaciones de marketing que creemos que pueden ser de su interés.  Para más información sobre cómo funciona la 
								publicidad dirigida, puede visitar la página educativa de la Iniciativa Publicitaria en la Red ("NAI" por sus siglas en inglés) 
								en <a href='http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work' style={{cursor: "pointer", color: "lightblue", textDecoration: "none"}}>NetworkAdvertising. </a>
							<br/><br/>
								Puede darse de baja de la publicidad dirigida mediante el siguientes enlace:
							<br/><br/>
								- GOOGLE: 
								<a href='https://adssettings.google.com/authenticated?hl=es' style={{cursor: "pointer", color: "lightblue", textDecoration: "none"}}> ADSSettingsGoogle </a>
							<br/><br/>
								Además, puede darse de baja de algunos de estos servicios visitando el portal de exclusión voluntaria de Digital Advertising Alliance 
								en: 
								<a href='ttp://optout.aboutads.info/' style={{cursor: "pointer", color: "lightblue", textDecoration: "none"}}> Digital Advertising Alliance. </a>
								
							<br/>
							
							<br/>
							<b>NO RASTREAR</b>				
							<br/>
								Tenga en cuenta que no alteramos las prácticas de recopilación y uso de datos de nuestro Sitio cuando vemos una señal de No rastrear 
								desde su navegador.
							<br/>
							
							<br/>
							<b>RETENCIÓN DE DATOS</b>
							<br/>
								Cuando realiza un pedido a través del Sitio, mantendremos su Información del pedido para nuestros registros a menos que y hasta que nos 
								pida que eliminemos esta información.
							<br/>
							
							<br/>
							<b>CAMBIOS</b>
							<br/>
								Podemos actualizar esta política de privacidad periódicamente para reflejar, por ejemplo, cambios en nuestras prácticas o por otros
								motivos operativos, legales o reglamentarios.
							<br/>

							<br/>
							<b>CONTÁCTENOS</b>
							<br/>
								Para obtener más información sobre nuestras prácticas de privacidad, si tiene alguna pregunta o si desea presentar una queja, contáctenos por correo electrónico a ag@agsegurosweb.com o por correo mediante el uso de la información que se proporciona a continuación:
							<br/><br/>
								Av. Rafael Nuñez 4618 Loc 1, 3 y 5, Córdoba, X, 5009, Argentina
							<br/><br/>

							</Subtitle>
							<PrivacyPoliciesButton 
								initial={initial}
								transition={{ delay: 1, duration: 0.6 }}
								animate={animation}
								inverse={+inverse}
								primary={primary}
								onClick={() => redirectLogic(redir, linkTo)}
							>
								{buttonLabel}
							</PrivacyPoliciesButton>
						</TextWrapper>
			</Container>
		</Section>
	);
};

export default PrivacyPolicies;