

export const loginOne = {
	reverse: false,
	inverse: false,
	topLine: {
		text: '',
	},
	headline: 'Bienvenido!, este es el acceso para socios y productores',
	description: 'Si ya tiene un usuario puede ingresar, si quiere hacerse socio haga click en hacerme socio',
	buttonLabel: 'Ingresar',
	buttonLabel2: 'Cerrar Sesión',
	buttonLabel3: 'Hacerme Socio',
	buttonLabel4: 'Ver Pólizas',

	linkTo: null,
	redir: null,
	imgStart: 'start',
	img: './assets/svg/ChartUp.svg',
	start: 'true',
};

/*
export const constructionTwo = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Nos iniciamos en el año 1991',
	},
	headline: "Más de tres décadas en el negocio",
	description: 'Contamos con la experiencia y el respaldo de empresas lideres.',
	buttonLabel: 'Conócenos',

	linkTo: '/quienes-somos',
	redir: null,
	imgStart: 'start',
	img: './assets/svg/Deal.svg',
	start: 'true',
};

export const constructionThree = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Highly reputed brand',
	},
	headline: 'Why us? ',
	description:
		'Our 9 year experience have allowed us to use the most innovative technologies and methodologies',
	buttonLabel: 'View Project',

	linkTo: null,
	redir: null,
	imgStart: '',
	img: './assets/svg/ChartUp.svg',
	start: 'true',
};*/
