import React from 'react';
import {
	FooterLinkItems,
	FooterLinkTitle,
	FooterLink,
	FooterLogo,
	SocialIcon,
	FooterRights,
	FooterSocialIcon,
	FooterWrapper,
	FooterAddress,
	FooterColumn,
	FooterGrid,
} from './FooterStyles';
import { footerData, footerSocialData } from '../../data/FooterData';
import { Row, Section } from '../../globalStyles';
import { googlemaps } from '../../ReactGlobalData';

function Footer() {

	const redirectLogic = (dir) => {
		window.open(dir,"_blank");
	}

	const goToTop = () => {
		document.body.scrollIntoView();
	};

	return (
		<Section padding="0 2rem 0">
			<FooterWrapper>
				<FooterGrid justify="space-between">
					<FooterColumn id="footerLogo">
						<FooterLogo to="/" onClick={() => goToTop()}>
							<SocialIcon src="./assets/logo.png" />
							{/* Grupo AG */}
						</FooterLogo>
						<FooterAddress onClick={() => redirectLogic(googlemaps)}>
							Av. Rafael Núñez 4618 Loc 1, 3 y 5, X5009CGN Córdoba, Argentina
						</FooterAddress>

						<Row align="center" margin="auto  0 0 0" gap="1rem">
							{footerSocialData.map((social, index) => (
								<FooterSocialIcon onClick={() => redirectLogic(social.link)}
									key={index}
									target="_blank"
									aria-label={social.name}
								>
									{social.icon}
								</FooterSocialIcon>
							))}
						</Row>
					</FooterColumn>
					{footerData.map((footerItem, index, ) => (
						<FooterLinkItems key={index}>
							<FooterLinkTitle > {footerItem.title} </FooterLinkTitle>
							{footerItem.links.map((link, linkIndex) => (
								<FooterLink key={linkIndex} to={footerItem.redir[linkIndex]} onClick={() => goToTop()}>
									{link}
								</FooterLink>
							))}
						</FooterLinkItems>
					))}
				</FooterGrid>
				<FooterRights> GrupoAG © 2023 </FooterRights>
			</FooterWrapper>
		</Section>
	);
}

export default Footer;