import React from 'react'
import Disclaimer from '../components/Disclaimer/Disclaimer';
import { disclaimerOne } from '../data/DisclaimerData';

const DisclaimerPage = () => {

  return (
    <>
      <Disclaimer {...disclaimerOne }/>
    </>
  )
}

export default DisclaimerPage