import styled from 'styled-components';

const getColor = (props) => {
  if (props.isDragAccept) {
      return '#00e676';
  }
  if (props.isDragReject) {
      return '#ff1744';
  }
  if (props.isFocused) {
      return '#2196f3';
  }
  return '#eeeeee';
}

const getBackgroundColor = (props) => {
  if (props.isDragAccept) {
      return '#43ff6433';
  }
  if (props.isDragReject) {
      return '#ff00004d';
  }
  if (props.isFocused) {
      return '#0082ff4d';
  }
  return '#fafafa';
}

export const Container = styled.div`
  margin: 3rem;
  cursor: pointer;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${props => getColor(props)};
  border-style: solid;
  background-color: ${props => getBackgroundColor(props)};
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
`;

export const DropZoneText = styled.p`
  color: black;
`;

export const DropZone = styled.input`

`;

