//const WAURL = "https://api.whatsapp.com/send?phone=%2B5493512050050&text=Hola%2C+quiero+contratar+un+seguro%0A";
import { consultar_wapp } from '../ReactGlobalData';


export const data = [
/* 	{
		to: '/',
		text: 'Caracteristicas',
		id: 'about',
	}, */
	{
		to: '/productos',
		redir: null,
		text: 'Productos',
	},
	{
		to: '/login',
		redir: null,
		text: 'Acceso PAS',
	},
	{
		to: '/asistencia-y-siniestros',
		redir: null,
		text: 'Siniestros',
	},
	{
		to: null,
		redir: consultar_wapp,
		text: 'Contáctanos',
	},
];
