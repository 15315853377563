import { asistencia_wapp, siniestros_wapp, formSiniestros, pdfSiniestros } from './../ReactGlobalData'

export const siniestrosOne = {
	reverse: false,
	inverse: false,
	topLine: {
		text: '',
	},
	headline: 'Asistencia y Siniestros',
	description: 'Solicitar asistencia y/o reportar un siniestro.',
	buttonLabel0: 'Consulta por Siniestros',
	buttonLabel1: 'Denuncia Automotor Online',
	buttonLabel2: 'PDF Denuncia Automotor',
	linkTo: null,
	redir: null,
	wapp0: asistencia_wapp,
	wapp1: siniestros_wapp,
	formSiniestros: formSiniestros,
	pdfSiniestros: pdfSiniestros,
	imgStart: 'start',
	img: './assets/svg/Deal.svg',
	start: 'true',
};