import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';

export default function ClientFormStep() {
  return (
    <Box
      component="form"
      sx={{
        '& .MuiTextField-root': { m: 1, width: '100%'},
      }}
      noValidate
      autoComplete="off"
    >
        <Box sx={{ flexGrow: 1 }}>
            <Grid container alignItems="center" columns={10} spacing={4} components={'DesktopDateTimePicker'}>
                <Grid item alignItems="center" xs={10} sx={{
                    marginTop:"50px",
                    width:"100%"}}>
                    <TextField
                        id="outlined-textarea"
                        label="Domicilio en dónde ocurrió el Siniestro"
                        placeholder="Escriba aquí el domicilio aclarando calle, altura y ciudad"
                        multiline
                    />
                </Grid>
                <Grid item alignItems="center" xs={10}>
                    <TextField
                    id="outlined-multiline-static"
                    label="Relato describiendo de manera precisa lo sucedido en el Siniestro"
                    multiline
                    rows={10}
                    placeholder='Describa de manera precisa lo sucedido en el siniestro'
                    defaultValue=""
                    />
                </Grid>
            </Grid>
        </Box>
    </Box>
  );
}