
var title = 'Grupo AG: Una historia de perseverancia y compromiso con el cliente.';
var desc0 = 'En 1991, Antonio Garcia, un joven emprendedor con un gran conocimiento en el sector de los seguros, decidió iniciar su propio negocio. Así nació GrupoAG, una sociedad de Productores Asesores de Seguros con una visión clara: ofrecer un servicio personalizado y de calidad a sus clientes.';
var desc1 = 'Con el tiempo, GrupoAG se convirtió en una empresa líder en el mercado. Su filosofía de trabajo, basada en la honestidad, la transparencia y la dedicación, ganó la confianza de clientes y empresas del sector. Hoy en día, GrupoAG trabaja con algunas de las compañias mas importantes del país, ofreciendo soluciones en seguros de vida, de salud, de hogar, de autos y de empresas.';
var desc2 = 'Pero lo más importante para GrupoAG sigue siendo el cliente. Cada día, su equipo de expertos trabaja para ofrecer la mejor cobertura, aasesoramiento y atención personalizada a cada uno de ellos. Saben que la tranquilidad y la seguridad que brindan sus seguros son fundamentales para la vida de de las personas y las empresas.';
var desc3 = 'En resumen, la historia de GrupoAG es una historia de perseverancia y compromiso con el cliente. Una empresa que nació hace más de 30 años con un objetivo claro y que hoy en día es reconocida como una de las más importantes del sector de los seguros. Siempre con la	misma pasión y dedicación, GrupoAG seguirá trabajando por brindad la mejor protección y	tranquilidad a sus clientes, hoy y en el futuro.';


export const aboutOne = {
	reverse: false,
	inverse: false,
	topLine: {
		text: '',
	},
	headline: title,
	description0: desc0,
	description1: desc1,
	description2: desc2, 
	description3: desc3,
	buttonLabel: 'Volver',

	linkTo: '/',
	redir: null,
	imgStart: 'start',
	img: './assets/svg/Logo.svg',
	start: 'true',
};

/*
export const aboutTwo = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Nos iniciamos en el año 1991',
	},
	headline: "Más de tres décadas en el negocio",
	description: 'Contamos con la experiencia y el respaldo de empresas lideres.',
	buttonLabel: 'Conócenos',

	linkTo: '/quienes-somos',
	redir: null,
	imgStart: 'start',
	img: './assets/svg/Deal.svg',
	start: 'true',
};

export const aboutThree = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Highly reputed brand',
	},
	headline: 'Why us? ',
	description:
		'Our 9 year experience have allowed us to use the most innovative technologies and methodologies',
	buttonLabel: 'View Project',

	linkTo: null,
	redir: null,
	imgStart: '',
	img: './assets/svg/ChartUp.svg',
	start: 'true',
};*/
