export const privacyOne = {
	reverse: false,
	inverse: false,
	topLine: {
		text: '',
	},
	headline: 'Política de privacidad de GRUPO-AG',
	subtitle: '___________________',
	buttonLabel: 'Volver',

	linkTo: '/',
	redir: null,
	imgStart: 'start',
	img: './assets/svg/Work.svg',
	start: 'true',
};