import {React, useEffect} from 'react';
import { Container, Section } from '../../globalStyles';
import {
	CreditsRow, 
	TextWrapper, 
	TopLine, 
	Heading, 
	CreditsButton, 
	Subtitle, 
	ImgWrapper, 
	Img, 
	CreditsColumn,
} from './CreditsStyle.js';
import { useInView } from 'react-intersection-observer';
import { useAnimation } from 'framer-motion';
import { useNavigate } from 'react-router-dom';
import { creditsOne } from '../../data/CreditsData';
export const Credits = ({
	primary,
	topLine,
	headline,
	subtitle,
	description,
	buttonLabel,
	img,
	alt,
	inverse,
	reverse,
	redir,
	linkTo,
}) => {

	let navigate = useNavigate();
	const initial = { opacity: 0, y: 30 };
	const animation = useAnimation();

	const { ref, inView } = useInView({ threshold: 0.2 });

	useEffect(() => {
		if (inView) {
			animation.start({
				opacity: 1,
				y: 0,
			});
		}
	}, [inView, animation]);


	const redirectLogic = (redlink, inslink) => {
		if (redlink !== null){
			window.open(redlink);
		}
		if (inslink !== null){
			navigate(inslink);
		}
	}

	return (
		<Section inverse={inverse} ref={ref}>
			<Container>
				<CreditsRow reverse={reverse}>
					<CreditsColumn>
						<TextWrapper>
							<TopLine
								initial={initial}
								transition={{ delay: 0.3, duration: 0.6 }}
								animate={animation}
							>
								{topLine.text}
							</TopLine>
							<Heading
								initial={initial}
								transition={{ delay: 0.5, duration: 0.6 }}
								animate={animation}
								inverse={+inverse}
							>
								{headline}
							</Heading>
							<Subtitle
								initial={initial}
								transition={{ delay: 0.7, duration: 0.6 }}
								animate={animation}
								inverse={+inverse}
							>
								{subtitle}
							</Subtitle>
						</TextWrapper>
					</CreditsColumn>
					<CreditsColumn
						initial={initial}
						transition={{ delay: 0.5, duration: 0.6 }}
						animate={animation}
					>
						<ImgWrapper>
							<Img
								src={img}
								alt={alt}
								whileHover={{ rotate: 2, scale: 1.02 }}
								transition={{ duration: 0.5 }}
							/>
						</ImgWrapper>
					</CreditsColumn>
				</CreditsRow>
				<TextWrapper>
					{creditsOne.description.map((description, descriptionIndex) => (		
						<Subtitle
								initial={initial}
								transition={{ delay: 0.7, duration: 0.6 }}
								animate={animation}
								inverse={+inverse}
								key={descriptionIndex}
							>
							+ {description}
							</Subtitle>))}
							<CreditsButton 
								initial={initial}
								transition={{ delay: 1, duration: 0.6 }}
								animate={animation}
								inverse={+inverse}
								primary={primary}
								onClick={() => redirectLogic(redir, linkTo)}
							>
								{buttonLabel}
							</CreditsButton>
						</TextWrapper>
			</Container>
		</Section>
	);
};

export default Credits;