export const sliderSettings = {
	arrows: false,
	slidesToShow: 3, //Cambiar a 3 maximo, si hay menos novedades poner ese numero.
	focusOnselect: false,
	accessability: false,
	responsive: [
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 2, // Cambiar a 2 máximo, POR DEFECTO 2
			},
		},
		{
			breakpoint: 900,
			settings: {
				slidesToShow: 1, // NO EDITAR, POR DEFECTO 1
			},
		},
	],
};
