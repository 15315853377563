import React from 'react';
//import { Link } from 'react-router-dom';
import { Button, Container, MainHeading } from '../../globalStyles';
import { HeroVideo, HeroSection, HeroText, ButtonWrapper/*, HeroButton*/ } from './HeroStyles';

var WAURL = "https://api.whatsapp.com/send?phone=%2B5493512050050&text=Hola%2C+quiero+contratar+un+seguro%0A"

const Hero = () => {

  const redirectFunc = () => {
    window.open(WAURL);
  }

  return (
    <HeroSection>
        <HeroVideo src="./assets/hero.webm" autoPlay muted loop/>
        <Container>
            <MainHeading> La solución en seguros que estabas buscando </MainHeading>

            <HeroText>

              En Grupo AG, ofrecemos una amplia gama de opciones en seguros y asesoramiento experto 
              para ayudarle a encontrar la mejor protección para usted y su empresa.
              <br/>
              ¡Contáctenos hoy mismo para obtener una cotización!

            </HeroText>
            <ButtonWrapper>
            
                <Button onClick={redirectFunc} > ¡Contáctanos! </Button>
                
                {/* 
                <HeroButton> Find More </HeroButton>
                 */}


            </ButtonWrapper>
        </Container>
    </HeroSection>
  )
}

export default Hero