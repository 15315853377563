import React from 'react'
//import Form from '../components/Form/Form';
import Form from '../components/NewForm/Form';
//import Siniestros from '../components/Siniestros/Siniestros';
//import { siniestrosOne } from '../data/SiniestrosData';

const SiniestrosFormPage = () => {

  return (
    <>
      <Form/>
      {/*<Form/>*/}
    </>
  )
}

export default SiniestrosFormPage