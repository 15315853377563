import styled from "styled-components";

export const ServicesWrapper = styled.div`
    font-family: 'raleway';
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #071c2f;
    padding-bottom: 40px;


    
    > h1{
        font-size: 3em;
        margin: 25px 0;
        text-align: center;
        font-weight: 500;
        color: #fff;
    }

    @media screen and (max-width: 991px) {
        padding: 25px;
        
        >h1 {
            font-size: 2.5em;
            font-weight: 600;
        }
    }

`;

export const ServicesContentBox = styled.div`
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 1000px;
    margin-top: 30px;

    @media screen and (max-width: 991px) {
        flex-direction: column;
        width: 100%;
    }
    
`;

export const ServicesCard = styled.div`
    min-height: 220px;
    width: 320px;
    padding: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    margin: 10px 4px;
    box-shadow: 0px 15px 25px rgba(0, 0, 0, 0.2);
    transition: 0.5s;
    cursor: pointer;

    > i {
        margin: 20px;
        color: blue;
    }

    > h2 {
        margin-bottom: 12px;
        font-weight: 400;
        text-align: center;
    }

    > p {
        color: #6c757d;
        text-align: center;
    }

    :hover{
        > p {
            color: #fff;
        }

        > h2 {
            font-weight: 600;
            color: #f2f2f2;
        }

        > i {
            filter: invert();
        }
    }

    :nth-child(1):hover{
        background: 
            linear-gradient(
                45deg, 
                rgba(0, 162, 194, 0.8) 0%, 
                rgba(0, 0, 82, 0.8) 100%
                ), 
            url('./assets/services/template.png');
        background-size: cover;
    }
    :nth-child(2):hover{
        background: 
            linear-gradient(
                45deg, 
                rgba(0, 162, 194, 0.8) 0%, 
                rgba(0, 0, 82, 0.8) 100%
                ), 
            url('./assets/services/template2.png');
        background-size: cover;
    }
    :nth-child(3):hover{
        background: 
            linear-gradient(
                45deg, 
                rgba(0, 162, 194, 0.8) 0%, 
                rgba(0, 0, 82, 0.8) 100%
                ), 
            url('./assets/services/template3.png');
        background-size: cover;
    }
    :nth-child(4):hover{
        background: 
            linear-gradient(
                45deg, 
                rgba(0, 162, 194, 0.8) 0%, 
                rgba(0, 0, 82, 0.8) 100%
                ), 
            url('./assets/services/template4.png');
        background-size: cover;
    }
    :nth-child(5):hover{
        background: 
            linear-gradient(
                45deg, 
                rgba(0, 162, 194, 0.8) 0%, 
                rgba(0, 0, 82, 0.8) 100%
                ), 
            url('./assets/services/template5.png');
        background-size: cover;
    }
    :nth-child(6):hover{
        background: 
            linear-gradient(
                45deg, 
                rgba(0, 162, 194, 0.8) 0%, 
                rgba(0, 0, 82, 0.8) 100%
                ), 
            url('./assets/services/template6.png');
        background-size: cover;
    }
    :nth-child(7):hover{
        background: 
            linear-gradient(
                45deg, 
                rgba(0, 162, 194, 0.8) 0%, 
                rgba(0, 0, 82, 0.8) 100%
                ), 
            url('./assets/services/template7.png');
        background-size: cover;
    }
    :nth-child(8):hover{
        background: 
            linear-gradient(
                45deg, 
                rgba(0, 162, 194, 0.8) 0%, 
                rgba(0, 0, 82, 0.8) 100%
                ), 
            url('./assets/services/template8.png');
        background-size: cover;
    }
    :nth-child(9):hover{
        background: 
            linear-gradient(
                45deg, 
                rgba(0, 162, 194, 0.8) 0%, 
                rgba(0, 0, 82, 0.8) 100%
                ), 
            url('./assets/services/template9.png');
        background-size: cover;
    }


    @media screen and (max-width: 991px) {
        min-width: 300px;
        margin: 10px auto;
    }

`;