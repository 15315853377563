import { consultar_wapp } from '../ReactGlobalData';


export const heroOne = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Nos iniciamos en el año 1991',
	},
	headline: "Más de tres décadas en el mercado",
	description: 'Contamos con la experiencia y el respaldo de empresas lideres.',
	buttonLabel: 'Conócenos',

	linkTo: '/quienes-somos',
	redir: null,
	imgStart: 'start',
	img: './assets/svg/Deal.svg',
	start: 'true',
	alt: 'Dos personas dandose la mano, cerrando un trato.',
};

export const heroTwo = {
	reverse: false,
	inverse: false,
	topLine: {
		text: 'Asesoramiento personalizado',
	},
	headline: 'Tu cobertura, al mejor precio',
	description: 'Nuestros clientes reciben seguros hechos a la medida para que no paguen de más.',
	buttonLabel: '¡Contáctanos!',

	linkTo: null,
	redir: consultar_wapp,
	imgStart: 'start',
	img: './assets/svg/ChartUp.svg',
	start: 'true',
	alt: 'Un productor asesorando a un cliente.',
};

/*
export const heroThree = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Highly reputed brand',
	},
	headline: 'Why us? ',
	description:
		'Our 9 year experience have allowed us to use the most innovative technologies and methodologies',
	buttonLabel: 'View Project',

	linkTo: null,
	redir: null,
	imgStart: '',
	img: './assets/svg/ChartUp.svg',
	start: 'true',
};*/
