import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import ClientFormStep from '../NewForm/ClientFormStep';
import ThirdFormStep from '../NewForm/ThirdFormStep';
import SinisterFormStep from '../NewForm/SinisterFormStep';
import DropZoneFormStep from '../NewForm/DropZoneFormStep';
import ResumeFormStep from '../NewForm/ResumeFormStep';

const steps = ['Datos del Asegurado', 'Datos del Tercero', 'Relato del Siniestro', 'Subir Imágenes', 'Finalizar'];

export default function FormStepper() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Box sx={{ width: '100%' }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label} completed={completed[index]}>
            <StepButton color="inherit" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
        {allStepsCompleted() ? (
          <React.Fragment>
            <Typography sx={{ mt: 2, mb: 1 }}>
              El siniestro ya fue reportado!.
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Terminar</Button>
            </Box>
          </React.Fragment>
        ) : (
          <React.Fragment>
            { activeStep === 0 && <ClientFormStep/>}
            { activeStep === 1 && <ThirdFormStep/>}
            { activeStep === 2 && <SinisterFormStep/>}
            { activeStep === 3 && <DropZoneFormStep/>}
            { activeStep === 4 && <ResumeFormStep/>}

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                color="inherit"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Volver
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleComplete}>
                {completedSteps() === totalSteps() - 1
                  ? 'Siguiente'
                  : 'Siguiente'}
              </Button>
            </Box>
          </React.Fragment>
        )}
      </div>
    </Box>
  );
}