import React from 'react';
import ReactDOM from 'react-dom/client';
import {Auth0Provider} from '@auth0/auth0-react'
import App from './App';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <Auth0Provider 
    domain='agsegurosweb.us.auth0.com' 
    clientId='aCIEmXh40HTnKxAWZmlCRm9iHDr4hedi' 
    authorizationParams={{
      redirect_uri: 'https://agsegurosweb.com/productores'
    }}>
        <App />
    </Auth0Provider>
  </React.StrictMode>
);

