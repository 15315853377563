import {React,  useState} from 'react';
import { FaArrowCircleLeft, FaArrowCircleRight } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { sliderSettings } from '../../data/CarouselData';
import { Row, Heading, Section, TextWrapper } from '../../globalStyles';
import {
	ButtonContainer,
	ReviewSlider,
	ImageWrapper,
	CarouselImage,
	CardButton,
} from './CarouselStyles';

async function getNews(){
    return await fetch( "https://script.google.com/macros/s/AKfycbwpGia8bGAoDciBHh52QxubfX97Xa2nK-OuD2ll6mUIs7uANG2uqjjkxwzsXE3nEr7cOA/exec?action=getNews", {
        method: 'GET',
    }).then(response => response.json())   
}


const Carousel = () => {
	const [sliderRef, setSliderRef] = useState(null);
	const [data, setData] = useState([]);
	const [needData, setNeedData] = useState(true)

	const redirectLogic = (dir) => {
		console.log(dir);
		if (dir !== null){
			window.open(dir);
		}
	}

	if(needData){
		getNews().then(response=>{
			if(response != null){
				if(response.length > 0){
					setData(response)
					setNeedData(false)
				}
			  	else{
					setData([])
					setNeedData(false)
			  	}
			}
			else{
				setData([])
				setNeedData(false)
			}
		})
	}
	
	return (
		<Section margin="auto" maxWidth="1280px" padding="50px 70px" inverse>
			<Row justify="space-between" margin="1rem" wrap="wrap">
				<Heading width="auto" inverse>
					Noticias & Promociones
				</Heading>
				<ButtonContainer>
					<IconContext.Provider value={{ size: '48px', color: '#1d609c' }}>
						<FaArrowCircleLeft onClick={sliderRef?.slickPrev} />
						<FaArrowCircleRight onClick={sliderRef?.slickNext} />
					</IconContext.Provider>
				</ButtonContainer>
			</Row>

			<ReviewSlider {...sliderSettings} ref={setSliderRef}>
				{data.map((data, index) => (
					<ImageWrapper key={index} onClick={() => redirectLogic(data.redir)}>
						<CarouselImage src={data.image} />
						<TextWrapper size="1.1rem" margin="0.4rem 0 0" weight="bold">
							{data.title}
						</TextWrapper>
						<TextWrapper size="0.9rem" margin="0.7rem" color="#4f4f4f">
							{data.description}
						</TextWrapper>
						<CardButton onClick={() => redirectLogic(data.redir) }> Ver más </CardButton>
					</ImageWrapper>
				))}
			</ReviewSlider>
		</Section>
	);
};

export default Carousel;