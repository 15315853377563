// Whatsapp Links
export const automotor_wapp  = 'https://api.whatsapp.com/send?phone=%2B5493512050050&text=Hola+quiero+cotizar+un+seguro+para+mi+auto%2Fmoto%3A+%0A-+Nombres%3A+%0A-+Apellidos%3A+%0A-+DNI%3A+%0A-+Fecha+de+nacimiento%3A+%0A-+Modelo%3A+%0A-+A%C3%B1o%3A+%0A-+0km+%28si%2Fno%29%3A+%0A-+GNC+%28si%2Fno%29%3A+%0A-+Patente%2FDominio%3A+%0A-+Domicilio%3A+%0A-+E-Mail%3A+%0A-+C%C3%B3digo+de+descuento%3A+';
export const hogar_wapp 	 = 'https://api.whatsapp.com/send?phone=%2B5493512050050&text=Hola%21%2C+quiero+contratar+un+seguro+para+el+Hogar%3A';
export const personas_wapp 	 = 'https://api.whatsapp.com/send?phone=%2B5493512050050&text=Hola%21%2C+quiero+contratar+un+seguro+de+Personas%3A';
export const empresas_wapp 	 = 'https://api.whatsapp.com/send?phone=%2B5493512050050&text=Hola%21%2C+quiero+contratar+un+seguro+para+mi+Empresa%3A';
export const asistencia_wapp = 'https://api.whatsapp.com/send?phone=%2B5493512050050&text=Hola+necesito+asistencia%3A';
export const art_wapp        = 'https://api.whatsapp.com/send?phone=%2B5493512050050&text=Hola+quiero+contratar+un+seguro+de+ART.';
export const consultar_wapp  = 'https://api.whatsapp.com/send?phone=%2B5493512050050&text=Hola+quiero+consultar+por+un+seguro%3A';
export const siniestros_wapp = 'https://api.whatsapp.com/send?phone=%2B5493512050050&text=Hola+quiero+reportar+un+siniestro%3A%0A';

// Insurance Companies for Employees
export const meridional_link = 'https://ws1.meridionalseguros.com.ar/login.asp';
export const mercantil_link = 'https://servicios.mercantilandina.com.ar/sigmav3/';

// Insyrance Companies for Clients
export const asociart_home = 'https://www.asociart.com.ar/';
export const berkley_home = 'https://www.berkley.com.ar/';
export const beneficio_home = 'https://www.beneficioweb.com.ar/';
export const galeno_home = 'https://www.galenoseguros.com.ar/seguros/';
export const integrity_home = 'https://integrityseguros.com.ar/';
export const meridional_home = 'https://www.meridionalseguros.com.ar/';
export const mercantil_home = 'https://www.mercantilandina.com.ar/';
export const prevencion_home = 'https://www.prevencionsalud.com.ar/';
export const rivadavia_home = 'https://www.segurosrivadavia.com/personas/';
export const sancristobal_home = 'https://www.sancristobal.com.ar/institucional/';
export const sancor_home = 'https://www.sancorseguros.com.ar/';
export const sura_home = 'https://www.segurossura.com.ar/';
export const swissmedical_home = 'https://www.swissmedicalseguros.com/';
export const zurich_home = 'https://www.zurich.com.ar/es-ar';

// Others
export const instagram = "https://www.instagram.com/grupoag.seguros/";
export const googlemaps = "https://goo.gl/maps/4JfrYYDB2AvdspCG7";
export const formSiniestros = 'https://docs.google.com/forms/d/14aBbYOVS5uZSViarakJbrii1u3LueiCJBalojQbMgIo/viewform?edit_requested=true';
export const pdfSiniestros = 'https://drive.google.com/file/d/1dpYa9gUai-KNrpjgk8MYklpXKDUDTpJ7/view?usp=share_link';